import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="title-section">
        <small style={{display: 'inline-block',paddingBottom: '1rem'}}></small>
        <h1 className="title is-size-1">Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
